import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

const Hero = loadable(() => import('/src/components/PageBuilder/layouts/standard/Hero'))
const IntegratedTextField = loadable(() => import('/src/components/Form/IntegratedTextField'))

const validationSchema = Yup.object({
    reg: Yup.string()
})

const CarRegHero = ({
    title,
    content,
    mediaLocation,
    mediaType,
    image,
    imageAlignment,
    url,
    addAffiliate,
    utm,
    addSnow
}) => {
    const regArea = (
        <>
            <Formik
                initialValues={{
                    reg: ''
                }}
                onSubmit={async (values) => {
                    // Test reg: GV18 BYZ
                    const reg = values.reg.replace(/\s/g, '') // remove spaces
                    window.open(`${url}&reg=${reg}`, '_blank')
                }}
                validationSchema={validationSchema}
            >
                {() => (
                    <Form>
                        <IntegratedTextField
                            label={'Please enter your registration number:'}
                            name={'reg'}
                            placeholder={'e.g. KZ66 ZZYT'}
                            submitIcon={'external'}
                        />
                    </Form>
                )}
            </Formik>
        </>
    )

    return (
        <Hero
            columnSizes={'offset-1-media-5-content-5'}
            title={title}
            content={content}
            mediaLocation={mediaLocation}
            mediaType={mediaType}
            image={image}
            imageAlignment={imageAlignment}
            node={regArea}
            addExtended={true}
            extendedContent={`<a href=${url} target="_blank">Don't have your registration number?</a></Hero>`}
            addAffiliate={addAffiliate}
            utm={utm}
            addSnow={addSnow}
        />
    )
}

CarRegHero.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    mediaLocation: PropTypes.oneOf(['left', 'right']).isRequired,
    mediaType: PropTypes.oneOf(['image', 'video']).isRequired,
    image: PropTypes.object,
    imageAlignment: PropTypes.oneOf(['default', 'top', 'bottom']).isRequired,
    url: PropTypes.string,
    addAffiliate: PropTypes.bool,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string,
    addSnow: PropTypes.bool
}

CarRegHero.defaultProps = {
    title: 'Car Insurance',
    content: `<p>Compare Car Insurance quotes from over 110+ UK providers and save up to £319*</p>`,
    mediaLocation: 'right',
    mediaType: 'image',
    imageAlignment: 'default',
    url: 'http://www.protectline.co.uk',
    addAffiliate: false,
    addSnow: false
}

export default CarRegHero

export const query = graphql`
    fragment CarRegHeroForPageBuilder on WpPage_Acfpagebuilder_Layouts_CarRegHero {
        fieldGroupName
        title
        content
        mediaLocation
        mediaType
        image {
            id
            localFile {
                childImageSharp {
                    gatsbyImageData(width: 520)
                }
            }
            altText
            title
        }
        imageAlignment
        url
        addAffiliate
    }
`
